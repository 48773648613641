<template>
  <div id="documentation" class="container" :style="{ backgroundImage: 'url(' + require('@/assets/background.png') + ')' }">
    <div class="w-1200 full-height">
      <div class="text-box">
        <p>The Microbiome Forensics UZH database UZH contains data files for selected samples from <a href="https://www.microbeatlas.com" target="_blank">Microbe Atlas version 2018</a>.<br/>Microbe Atlas collects and processes microbial sequencing reads from publicly available datasets uploaded to SRA.<br/>The pipeline for read quality control, and the generation of OTU abundance as well as taxonomic and OTU classification is detailed <a href="https://www.microbeatlas.org/index.html?action=help" target="_blank">here</a>.</p>
        <p>For the Microbiome Forensics DB UZH, we selected samples from forensically relevant human body sites including urine, nostril, breast, feces, menstrual blood, skin, semen, saliva, amniotic fluid, vaginal fluid and venous blood.<br/>To do so, we checked the keywords in the metadata files of these samples.<br/>In addition, we conducted a preliminary curation filtering to remove samples with ambiguous bodily origin for which further data inspection is necessary.<br/>For this step, we removed those samples that had more than one body site as keywords in their metadata.</p>
        <p>We have collected the samples, their associated OTU read tables and taxonomic files in a Mongo database that contains a total of 217229 samples.</p> 
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'

export default {
  name: 'Documentation',
  components: {
    Footer
  }
}
</script>

<style scoped>


.text-box {
  padding: 30px;
  background-color: rgb(212 228 231 / 55%);
  color: var(--black);
}

</style>
