<template>
  <div class="header">
    <div class="logo-container">
      <img src="@/assets/irm.png" width="151" height="130"/>
      <span> Microbiome Forensics Database <sup>UZH</sup></span>
    </div>
    <div class="navigation">
      <nav>
        <router-link to='/'>Home</router-link>
        <router-link to='/about'>About</router-link>
        <router-link to='/search'>Search</router-link>
        <router-link to='/guide'>User Guide</router-link>
        <router-link to='/documentation'>Documentation</router-link>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<style scoped>
.header {
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--bluelight);
}

.logo-container {
  display: flex;
  align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
}

.logo-container img {
  max-width: 100px; 
  height: auto;
}

.logo-container span {
  font-size: 20px;
}

.navigation {
  padding: 10px;
    width: 100%;
    background: var(--bluelight2);
    border-top: 1px solid var(--border);
    border-bottom: 1px solid var(--border);
    margin-bottom: -3px;
}

nav {
  display: flex;
  gap:10px;
  justify-content: center;
}

nav a {
  color:var(--color-1);
  text-decoration: none;
    text-transform: uppercase;
    padding: 5px 0px;
    font-size: 16px;
    margin: 0px 5px;
}

nav a:hover {
  text-decoration: none;
  color:var(--orange);
  /*border-bottom: 2px solid var(--color-2);*/
}

nav .router-link-exact-active {
  /*border-bottom: 2px solid var(--color-2);*/
  color: var(--orange);
}

</style>
