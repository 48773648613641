<script>
import CheckBoxList from './CheckBoxList.vue'

export default { 
  extends: CheckBoxList, 
  name: 'HMP',
  methods: {
    itemCount(item) {
      if (this.dimensions == null) return 0;
      if (!(item in this.dimensions)) return 0;
      return this.dimensions[item];
    },
  },
  async mounted() {
    this.items = [
      'hmp',
      'no_hmp'
    ];
  }
};
</script>
