<template>
  <div id="home" class="container" :style="{ backgroundImage: 'url(' + require('@/assets/1920x600.jpg') + ')' }">
    <div class="content-container w-1200 grid-2 full-height">
      <div class="left-container">
        <h1>Data mining resource to obtain OTU abundance tables and sample information from human microbiome studies.</h1>
        <h2>This is currently a test version and data curation has not yet been completed. A new release is in progress and will be ready soon</h2>
      </div>
      <div class="right-container">

      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Footer
  }
}
</script>

<style scoped>

#home {
  background-repeat: no-repeat;
  background-size: cover;
}


.content-container p {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 10px;
}

@media screen and (max-width:1200px) {

  #home .grid-2 {
    grid-template-columns: 1fr;
  }

  .right-container {
    display: none;
  }
  .left-container {
    text-align: center;
  }

}

</style>

