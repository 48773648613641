import Home from './components/Home.vue';
import About from './components/About.vue';
import Search from './components/Search.vue';
import Guide from './components/Guide.vue';
import Documentation from './components/Documentation.vue';

const routes = [
    { path: '/', component: Home },
    { path: '/about', component: About },
    { path: '/search', component: Search },
    { path: '/guide', component: Guide },
    { path: '/documentation', component: Documentation },
];

export default routes;
