<template>
  <div id="about" class="container" :style="{ backgroundImage: 'url(' + require('@/assets/background.png') + ')' }">
    <div class="w-1200 about-container full-height">

      <div class="about-box">
        <div class="about-inner-box inner-img-box">
          <img src="@/assets/db.png">
        </div>
        <div class="about-inner-box inner-text-box">
          <h2>The Database</h2>
          <p>Contains microbial community information for samples from forensically relevant body sites, all of which are publicly available. In the Search page, you can select the types of samples of interest, and download the read count data as well as taxonomic classification files.</p>
          <p>You can use the data here to generate a reference that can be used to train machine learning algorithms for body site classification.</p>
        </div>
      </div>

      <div class="about-box about-box-revert">
        <div class="about-inner-box inner-img-box">
          <img src="@/assets/team.png">
        </div>
        <div class="about-inner-box inner-text-box">
          <h2>The Team</h2>
          <p>We are a group at the Forensic Genetics Department of the University of Zurich, investigating the application of microbiome-based analyses in forensics. </p>
          <p>We focus on the identification of body fluids/tissues in stains, while also exploring other topics such as the estimation of time since deposition, and identification of donors.For more information, see details on our FORMICS project at our <a href="https://www.irm.uzh.ch/de/forschung/genetik/Aktuelle-Forschungsprojekte/Microbial-forensics.html" target="_blank">IRM website</a> and the <a href="www.natashaarora.org" target="_blank">Formics Project</a>.</p>
        <p>To generate the database provided here, we teamed up with Joao Rodrigues and his colleagues at the Institute of Molecular Life Sciences, capitalizing on data produced for <a href="https://www.microbeatlas.org" target="_blank">Microbe Atlas</a>.</p>
        </div>
      </div>

    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'

export default {
  name: 'About',
  components: {
    Footer
  }
}
</script>

<style scoped>


.about-container {
  display: flex;
    flex-direction: column;
    gap: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.about-box {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 0px;
  width: 900px;
  margin: 0px auto;
  align-items: center;
  /*min-height: 350px;
  box-shadow: 0px 0px 15px #a5a5a5;*/
}

.inner-img-box {
  background-size: cover;
  background-repeat: no-repeat;
}

.inner-img-box img {
  animation:spin 85s linear infinite;
}

@keyframes spin { 100% { -webkit-transform: rotate(0deg); transform:rotate(360deg); } }

.inner-text-box {
  padding: 30px;
  background-color: rgb(212 228 231 / 55%);
  color:var(--black);
}

.inner-text-box h2 {
  color: var(--blue);;
}

.inner-text-box a:hover {
  color:var(--blue);
}

@media screen and (max-width:900px) {
  .about-box {
    width: 100%;
    grid-template-columns: 1fr 2fr;
  }

  .inner-img-box {
    background-position:right;
  }
  
}

@media screen and (max-width:600px) {

  .about-container {
    padding-top: 10px;
  }

  .about-box {
    grid-template-columns: 1fr;
    min-height: auto;
    width: 80%;
  }

  .inner-img-box {
    display:none;

  }
}

</style>
