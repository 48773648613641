<template>
  <div id="guide" class="container" :style="{ backgroundImage: 'url(' + require('@/assets/background.png') + ')' }">
    <div class="w-1200 full-height">
      <details open>
        <summary>Sample selection and Data download</summary>
        <div class="faq-content">
          <p>To choose the samples of interest, go to the Search page and select from the menus. You can choose samples according to 3 categories: body site of interest, type of sequencing data (shotgun, amplicon or transcriptomic sequences), and sequencing instrument. You can visualize the selected samples in a pie chart to see the distribution per category. Once you are satisfied with your selection, you can download the associated OTU abundance and metadata file (json file). The OTU abundance and metadata file contains the read counts for OTUs at the 97, 98, 99% thresholds. You can also download the full taxonomic file for all samples in the database (not only the selected samples).</p> 
          <p>For details on how these files were generated, please see <router-link to='/documentation'>Documentation</router-link>.</p>
          <p>For downstream analyses, you will probably want the OTU abundance, metadata and taxonomic information as tables. You may also want to remove taxa not present in your samples from the taxonomic files. You can follow these general steps in order to generate the files:</p>
          <ol>
            <li>Import the json OTU abundance and metadata file</li>
            <li>Generate 3 separate OTU abundance tables for the 97, 98, 99% identity thresholds</li>
            <li>Generate a separate metadata file</li>
            <li>Import the json taxonomic file</li>
            <li>Generate a taxonomic table keeping only the unique taxa in the three OTU abundance tables</li>
            <li>load your specific result file, and extract it</li>
          </ol>
          <p>Here is an example R script you can download which shows these steps: <a href="json2table.R" target="_blank">download example</a>.</p>
        </div>
      </details>
      <details>
        <summary>Alternative taxonomic analyses</summary>
        <div class="faq-content">
          <p>You can also use the OTU table to carry out taxonomic classification with a method of your choice, for instance, using <a href="http://www2.decipher.codes/Classification.html" target="_blank">DECIPHER</a>, also possible in <a href="https://benjjneb.github.io/dada2/tutorial.html) or QIIME2 (https://docs.qiime2.org/2022.2/data-resources/" target="_blank">DADA2</a>. </p>
        </div>
      </details>
      <details>
        <summary>Diversity analyses</summary>
        <div class="faq-content">
          <p>The OTU abundance table, metadata file and taxonomic file can be used with R packages such as phyloseq and Rhea, or with programmes such as QIIME2, in order to conduct out alpha and beta diversity analyses.</p>
        </div>
      </details>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'

export default {
  name: 'Guide',
  components: {
    Footer
  }
}
</script>

<style scoped>

details {
  max-width: 900px;
  margin: 0px auto 10px;
  padding: 0;
}

summary {
  font-size: 24px;
  font-weight: 600;
  background-color:rgb(239 244 245 / 55%);
  color:#333;
  padding: 1rem;
  margin: 0px;
  outline: none;
  text-align: left;
  cursor: pointer;
  position: relative;
  border:1px solid var(--border);
}

details[open] summary {
  background-color:rgb(212 228 231 / 55%);
}

details > summary::after {
  position: absolute;
  content: "+";
  right: 20px;
}
details[open] > summary::after {
  position: absolute;
  content: "-";
  right: 20px;
}
details > summary::-webkit-details-marker {
  display: none;
}

.faq-content {
  background: rgb(239 244 245 / 55%);
  padding:30px;
  border-right:1px solid var(--border);
  border-bottom:1px solid var(--border);
  border-left:1px solid var(--border);;
  
}

.faq-content p {
  margin-top: 0px;
  font-size:18px; 
  line-height: 22px;
}

.faq-content ol {
  padding-left:35px;
}
.faq-content ol li {
  font-size: 18px;
  padding:3px 0px; 
}

@media screen and (max-width:1200px) {

  details {
    width: 80%;
  }

}


@media screen and (max-width:650px) {
 
  summary {
    padding:10px;
  font-size: 18px;}

  .faq-content {padding: 18px;}


}


</style>
