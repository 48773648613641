<template>
  <div id="app">
    <Header/>
    <router-view />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  name: 'App',
  components: {
    Header
  }

}
</script>

<style>

:root {
  --blue: #4b90a5;
  --bluelight: #d4e4e7;
  --bluelight2: #eff4f5;
  --white: #ffffff;
  --black: #202020;
  --orange: #f39019;
  --border: #cdcdcd;
  
}

@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;400;700&display=swap');

#app {
  font-family: 'Titillium Web', sans-serif;
  color: var(--black);
  margin: 0;
}

body {
  margin: 0px;
}

h1 {
  font-size: 30px;
  line-height: 34px;
  color: var(--black);
}

h2 {
  font-size: 26px;
  color: var(--black);
  margin: 0;
  line-height: 30px;
}

h3 {
  font-size: 22px;
  color: var(--black);
  margin: 0;
}

h4 {
  font-size: 20px;
  color: var(--black);
  margin: 0;
}

a {
  color: var(--black);
  font-weight: 600;
}


.w-1200 {
  width: 1200px;
  margin: 0px auto;
}

.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.full-height {
  min-height: 70vh;
  justify-content: center;
  align-items: center;
}

.container {
  background-color: var(--white);
  
}

a:hover {
  color:var(--orange);
}

.container > div {
  padding: 50px 0px;
}
/**SEARCH PAGE BUTTONS */

.toggle-button-off {
  height: 30px;
  width: 35px;
  border-radius: 0px !important;
  background: transparent;
  border: 0px !important;
  margin-right: 5px;
  font-size: 0px !important;
}

.toggle-button-off:before {
  content: '';
  position: absolute;
  height: 30px;
  width: 35px;
  background: white;
  top: 0px;
  left: 0px;
  border: 1px solid black;
}


.toggle-button-on {
  height: 30px;
  width: 35px;
  border-radius: 0px !important;
  background: transparent;
  border: 0px !important;
  margin-right: 5px;
  font-size: 0px !important;
}
 
.toggle-button-on:before {
  content: '';
  position: absolute;
  height: 30px;
  width: 35px;
  background: #e4f5fd;
  top: 0px;
  left: 0px;
  border: 1px solid black;
}

.toggle-button-on:after {
  content: '';
  position: absolute;
  left: 15px;
  top: 5px;
  width: 6px;
  height: 14px;
  border: solid #f39060;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}


/*
.toggle-button-off {
  width:68px;
  height: 34px;
  margin-bottom: 2px;
  background-color: var(--bluelight) !important;
  transition: all ease 300ms;
  border-radius: 100vw !important;
  text-align: right !important;
  padding: 0px 7px !important;
  position: relative !important;
  overflow: hidden;
  font-size: 12px !important;
  border: 1px solid var(--blue) !important;
}

.toggle-button-off:before {
  content: '';
    position: absolute;
    left: 1px;
    top: 1px;
    background: var(--bluelight);
    height: 28px;
    width: 28px;
    border-radius: 50%;
    border: 1px solid var(--blue);
}

.toggle-button-off:hover {
  background-color: var(--bluelight) !important;
  color: var(--blue) !important;
}

.toggle-button-on:hover {
  background-color: var(--bluelight2) !important;
  color: var(--blue) !important;
}

.toggle-button-on {
  width:68px;
  height: 34px;
  margin-bottom: 2px; 
  background-color: var(--bluelight2) !important;
  color: var(--blue) !important;
  transition:all ease 300ms;
  border-radius: 100vw !important;
  text-align: left !important;
  padding: 0px 8px !important;
  position: relative !important;
  overflow: hidden;
  font-size: 12px !important;
  border: 1px solid var(--blue) !important;
}

.toggle-button-on:after {
  content:'';
  position:absolute;
  right:2px;
  top:2px;
  background: var(--blue);
  height: 28px;
  width: 28px;
  border-radius: 50%;
}

.form-check table tr td {
  display: block;
  line-height: 15px;
}
.form-check table tr td:first-child {
  display: table-cell;
  padding-right:7px;
}
*/
/* RESPONSIVE */

@media screen and (max-width:1200px) {
  .w-1200 {width: 100%;}
  .footer-box {
  display: flex !important;
  flex-direction: column;
}

.footer-box img {
  width: 100%;
  height: auto;
}

.container {
  padding:20px;
}


}




</style>

