<script>
import axios from 'axios'
import CheckBoxList from './CheckBoxList.vue'

export default { 
  extends: CheckBoxList, 
  name: 'Categories',
  methods: {
    itemCount(item) {
      if (this.dimensions == null) return 0;
      if (!('category_' + item in this.dimensions)) return 0;
      return this.dimensions['category_' + item];
    }
  },
  async mounted() {
    axios.get(`${process.env.VUE_APP_FORBIOME_API}/categories`).then(r => {
      this.items = JSON.parse(r.data);
    }).catch(e => console.error(`could not get categories, ${e}`))
  }
};
</script>
