<template>
    <label for="toggle_button">
        <button :class="toggled ? 'toggle-button-on' : 'toggle-button-off'" @click="toggle">{{toggled ? 'ON' : 'OFF'}}</button>
    </label>
</template>
<script>

export default {
    name: 'ToggleButton',
    emits: ['toggle'],
    data: function() {
        return {
            toggled: false
        }
    },
    methods: {
        toggle() {
            this.toggled = this.toggled ? false : true;
            this.$emit('toggle', this.toggled)
        }
    }
}
</script>

<style scoped>
.toggle-button-off {
  appearance: none;
  background-color: #e4f5fd;
  border: 1px solid rgba(27, 31, 35, .15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  box-sizing: border-box;
  color: #50a7bc;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
}
.toggle-button-off:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.toggle-button-off:hover {
  background-color: #50a7bc;
  color: #e4f5fd;
}

.toggle-button-off:focus {
  box-shadow: rgba(81, 46, 164, 0.4) 0 0 0 3px;
  outline: none;
}

.toggle-button-off:disabled {
  background-color: #e4f5fd;
  border-color: rgba(27, 31, 35, .1);
  color: rgba(255, 255, 255, .8);
  cursor: default;
}

.toggle-button-off:active {
  background-color: #50a7bc;
  color: #e4f5fd;
  box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}

.toggle-button-on {
  appearance: none;
  background-color: #50a7bc;
  border: 1px solid rgba(27, 31, 35, .15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  box-sizing: border-box;
  color: #e4f5fd;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
}

.toggle-button-on:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.toggle-button-on:hover {
  background-color: #e4f5fd;
  color: #50a7bc;
}

.toggle-button-on:focus {
  box-shadow: rgba(81, 46, 164, 0.4) 0 0 0 3px;
  outline: none;
}

.toggle-button-on:disabled {
  background-color: #e4f5fd;
  border-color: rgba(27, 31, 35, .1);
  color: rgba(255, 255, 255, .8);
  cursor: default;
}

.toggle-button-on:active {
  background-color: #e9ffef;
  color: #e4f5fd;
  box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}
</style>