<template>
  <form>
    <h3>{{title}}</h3>
    <div class="form-group form-check" v-for="item in items" v-bind:key="item">
      <table border="0">
        <tr>
          <td><ToggleButton v-on:toggle="update(item)"/></td>
          <td><label class="form-check-label" :for="item">{{item}}</label></td>
          <td><label class="form-check-label" :for="item">({{itemCount(item)}})</label></td>
        </tr>
      </table>
    </div>
  </form>
</template>

<script>
import ToggleButton from './Toggle.vue'

export default {  
  name: 'CheckBoxList',
  components: {
    ToggleButton
  },
  props: {
    title: String
  },
  data: function() {
    return {
      items: [],
      selected: [],
      dimensions: {}
    }
  },
  methods: {
    updateDimensions(dimensions) {
      this.dimensions = dimensions;
    },
    itemCount(item) {
      console.log(item)
      return 0;
    },
    update(item) {
      if (this.selected.some(i => i === item)) {
        this.selected.pop(item);
        this.$emit('removed', item);
      } else {
        this.selected.push(item);
        this.$emit('added', item);
      }
    }
  }
};
</script>

<style scoped>
.box {
    text-align:center;
    margin-bottom: 30px;
}

.toggle_container {
    margin: 0px auto;
    background: #efefef;
    width: 120px;
    padding: 10px 0;
    border-radius: 30px;
    transition: all .25s;
}

.toggle_container.active {
    background: #e9ffef;
}

</style>
