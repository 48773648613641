<template>
  <div class="footer">
    <div class="w-1200 footer-box">
      <div class="footer-content left-logo-footer"><a href="https://www.uzh.ch" target="_blank"><img src="@/assets/uzh.png"/></a></div>
      <div class="footer-content"><b>Contact:</b> To get in touch with us, please go to our IRM pages at <a href="https://www.irm.uzh.ch/de/forschung/genetik/team.html">https://www.irm.uzh.ch/de/forschung/ genetik/team.html</a></div>
      <div class="footer-content right-logo-footer"><a href="https://www.irm.uzh.ch" target="_blank"><img src="@/assets/irm.png"/></a></div>
      
      
      
    </div>
  </div>

</template>

<script>
export default {
  name: 'Footer'
}

</script>

<style scoped>

.footer {
  background-color: var(--bluelight);
  color: var(--blue);
  border-top:1px solid var(--border);
  padding:10px 0px;
}

.footer-box {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 25px;
  justify-content: center;
  align-items: center;
}

.footer-content {
  text-align: center;
}

.right-logo-footer {
  text-align: right;
}
.left-logo-footer {
  text-align: left;
}

.footer-content img {
  max-width: 150px;
  height: auto;
}

</style>
